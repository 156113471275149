.dropdown-label-btn {
  width: 100%;
  text-align: left !important;
  padding-left: 0 !important;
  background-color: transparent !important;
}

.language-settings-dropdown-overlay {
  height: 250px !important;
  max-width: 400px;
  width: 100%;

  ul {
    height: 250px !important;
    overflow: auto;
  }
}

.lng-container-btn {
  max-width: 500px;
  width: 100%;
  text-align: left;
}

