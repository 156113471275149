.profile-wrapper {
  max-width: 600px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-self: center;
  padding: 24px;
}

.row {
  padding: 10px;
}
.edit-btn {
  background-color: #fff !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  font-size: 14px;
  height: 32px !important;
  padding: 7px 15px;
  border-radius: 6px;
  position: relative;
  font-weight: 400;
  text-align: center;
  border: 1px solid #d9d9d9 !important;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.88) !important;
  box-sizing: border-box;
}
.edit-btn:hover {
  color: rgba(72, 102, 255, 0.8) !important;
  border-color: rgba(72, 102, 255, 0.8) !important;
}

