@import '../../../style/variables.less';

.ai-search-data-container {
  width: 100%;
  height: fit-content;

  .ai-data-input {
    box-shadow: none;
    min-height: 0;
  }
  .ai-data-paragraph {
    box-shadow: none;
    min-height: 0;
    opacity: 1;
    cursor: text;
    border: none;
    color: @text-color;
    padding-left: 0px;
    padding-bottom: 0;
    margin: 0;
    padding-block: 5px;
    width: 100%;

    p {
      width: 100%;
    }
  }
  .buttons-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 10px;
  }
}

.ai-data-paragraph-text {
  white-space: normal;
  word-break: break-word;
}

.custom-text-button:not(:hover),
.custom-text-button:hover {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0px;
  margin-right: 50px;
}

.custom-text-button:hover,
.custom-text-button:hover .anticon,
.custom-text-button:hover .button-text {
  color: @message-color;
}

.custom-text-button:not(:hover),
.anticon .button-text {
  color: @text-color;
}

@media screen and (max-width: 590px) {
  .custom-text-button:not(:hover),
  .custom-text-button:hover {
    flex: 1;
  }
}

