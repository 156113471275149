.language-settings-tab {
  display: flex;
  flex-direction: row-reverse;
  ul {
    list-style-type: '\2014\a0';
  }
  .dropdown-container {
    display: flex;
    flex-direction: column;
  }
}

