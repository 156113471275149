#table {
  overflow: auto;
  box-shadow: 0px 3px 20px 1px #00000008;
  background: #ffffff73;
  cursor: pointer;
  border-radius: 10px;
}
#container {
  width: 100%;
  padding-top: 20px;
  padding-inline: 37px;
}
.conversation-url {
  display: flex;
  flex-direction: row;

  p {
    color: #7169fb;

    span {
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 590px) {
  #table {
    margin-inline: 20px;
    height: 40vh;
  }
}

