@import '../../../../../../style/variables.less';

.app-menu-close {
  height: 70px;
  width: 100%;
  padding-inline: 20px;
  border: 0;
  background-color: transparent;
}

.app-menu-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: @text-color;
}
.divider {
  height: 1px;
  background-color: @opposite-primary-color;
  margin-inline: 20px;
}

.app-menu-open {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  height: fit-content;
  width: 100vw;
  position: absolute;
  padding-inline: 20px;
  z-index: 1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: linear-gradient(90deg, #f3d1d6 0%, #ddf1f9 97.87%);

  .ant-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .menu-item {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 50px;
      width: 100%;
      margin-left: 0;
      color: @text-color;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      border: 0;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.app-menu-container {
  box-shadow: none;
  background-color: transparent;
  .app-menu-open {
    display: none;
  }
  .arrow {
    transform: rotate(0);
    left: 0;
    &:before,
    &:after {
      background-color: transparent;
      width: 3px;
      height: 10px;
      display: inline-block;
      position: absolute;
      border-bottom: 12px solid @text-color;
      top: 0;
      left: 0;
      transform: rotate(0);
    }
    &:before {
      transform: rotate(-135deg);
    }
    &:after {
      transform: rotate(135deg);
    }
  }
  &.active {
    box-shadow: 0px 4px 4px 0px #00000012;
    background: linear-gradient(90deg, #f3d1d6 0%, #ddf1f9 97.87%);
    .app-menu-open {
      display: block;
    }
    .arrow {
      transform: rotate(0);
      transform: translate(0, -6px);
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
  }
}

.arrow {
  width: 13px;
  height: 13px;
  position: relative;
  bottom: -5px;
  left: -10px;
  transition: 0.4s ease;
  margin-top: 2px;
  text-align: left;
  transform: rotate(45deg);
  float: right;
  &:before,
  &:after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 12px;
    height: 3px;
    background-color: @text-color;
    transition: 0.4s ease;
  }
  &:after {
    position: absolute;
    transform: rotate(90deg);
    top: -5px;
    left: 5px;
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

