@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '../../style/variables';

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background-color: #aaa;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #8b9ed3;
  border-radius: 8px;
}

body {
  font-family: 'Roboto', serif;
  padding: 0;
  margin: 0;
}

.guest-page-bg-shape {
  position: fixed;
  z-index: -1;
  overflow: hidden;
}

.guest-page-bg-shape:nth-child(1) {
  width: 100vw;
  height: 160vh;
  left: -3vw;
  top: 10vh;
  background: radial-gradient(50% 50% at 50% 50%, #a954d1 0%, rgba(244, 220, 255, 0) 80%);
}

.guest-page-bg-shape:nth-child(2) {
  width: 120vw;
  height: 193vh;
  left: 11vw;
  top: -101vh;
  background: radial-gradient(50% 50% at 50% 50%, #78d9fd 0%, rgba(218, 245, 255, 0) 100%);
}

.guest-page-bg-shape:nth-child(3) {
  width: 83vw;
  height: 133vh;
  left: -40vw;
  top: -50vh;
  background: radial-gradient(50% 50% at 50% 50%, #f74555 0%, rgba(255, 206, 211, 0) 100%);
}

.guest-page-bg-shape:nth-child(4) {
  width: 47vw;
  height: 76vh;
  left: -27vw;
  top: 45vh;
  background: radial-gradient(50% 50% at 50% 50%, #78d9fd 0%, rgba(218, 245, 255, 0) 100%);
}

#app-primary-button {
  width: 100%;
  margin-top: 0;
}

.top-floater {
  position: absolute;
  margin: 20px;
  top: 0;
  width: 90vw;
  display: flex;
  justify-content: space-between;

  .logo-container {
    width: 113px;
    height: 32px;
    margin-top: 7px;
  }

  .top-floater-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    .top-floater-right-text {
      margin-right: 20px;
      font-style: normal;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      color: @text-color;
    }

    #transparent-button {
      width: 189px;
      height: 44px;
    }
  }
}

.guest-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(111.34deg, #F6F6FF 0%, #CBCBFF 100%);
}

.login-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  gap: 55px;
  border: 0.5px solid rgba(10, 37, 64, 0.1);
  border-radius: 20px;
  box-shadow: 0 3px 20px 1px #00000008;
  background: #ffffff73;
  width: 500px;
}

.login-box-forgot-password {
  padding: 40px;
  width: 470px;
}

#login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: auto;
  width: 100%;

  .ant-form-item {
    margin-top: 0;
    margin-bottom: 16px;

    div {
      width: 100%;

      p {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
  }

  .ant-input-password {
    .ant-form-item-feedback-icon {
      margin-top: 2px;
    }

    .ant-input-password-icon {
      order: 2;
      margin-inline-start: 8px;
      margin-inline-end: 0;
    }
  }

  .ant-form-item-explain-error {
    margin-left: 28px;
  }

  .invite-create-session-form-item-confirm-password {
    margin-top: -16px;
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }

  .form-input-label {
    display: flex;
    justify-content: center;
    max-width: 400px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-block: 16px;
    color: @form-input-label;
  }

  .forgot-password-form-title {
    margin-top: 0;
    padding-bottom: 30px;
  }

  p {
    max-width: 400px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
    color: @text-color;
  }

  p.form-title {
    margin-bottom: 13px;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    color: @form-input-label;
  }

  .login-form-actions {
    font-weight: 400;
    width: 100%;
    display: flex;
    justify-content: space-between;

    #forgot-password-text-container {
      display: flex;
      justify-content: flex-end;
    }

    a {
      color: @text-color;
      text-decoration: underline;
    }

    .ant-form-item-explain-error {
      font-size: 13px;
    }

    .ant-checkbox-wrapper a {
      text-decoration: underline;
      color: inherit;
    }

    span.ant-checkbox-inner {
      border-color: transparent;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: rgba(@primary-color, 0.5);
    }
  }

  .form-item-forgot-password-button-wrapper {
    margin-bottom: 0;
    margin-top: 30px;
  }
}

.form-forgot-password {
  max-width: 600px;

  p {
    width: 100%;
  }
}

#login-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}

.login-form-button {
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .login-box {
    margin-top: 80px;
    flex-direction: column;
    box-shadow: none;
  }

  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }

  #login-form {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #login-form {
    padding-inline: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 590px) {
  .login-box {
    width: 90vw;
    padding: 0;

    .form-title {
      width: 100%;
    }
    .login-form {
      width: 100%;
    }
  }
  .top-floater {
    margin-top: 13px;

    .logo-container {
      width: 73px;
      height: 21px;
      margin-top: 7px;
    }
    .top-floater-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .top-floater-right-text {
        margin: 0;
        width: 189px;
        margin-bottom: 5px;
        text-align: center;
      }
    }
  }
  .login-box-forgot-password {
    padding-block: 20px;
    width: 90vw;
    .form-forgot-password {
      width: 100%;
    }
  }
}

