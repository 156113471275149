#conversation-container {
  width: 70vw;
}

#conversation-table {
  width: 100%;
  padding-bottom: 30px;
  padding-inline: 20px;
}

.login-button-container {
  width: 100%; 
  display: flex; 
  justify-content: flex-end; 
  margin-bottom: 20px;
}
