.storage-form {
  display: flex;
  flex-direction: column;
  padding: 0;
  .storage-form-main-settings {
    display: flex;
    flex-direction: column;
    .ant-form-item {
      max-width: 400px;
    }
  }
}

