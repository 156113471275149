@import '../../../src/style/variables.less';

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-right: 37px;

  .header-right-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    h2 {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 0;
      color: @text-color;
    }
    .storage-select {
      width: 200px;
      margin-top: 24px;
    }
    .create-storage-button {
      width: 200px;
      margin-top: 24px;
      margin-right: 12px;
    }
    .transparent-button-small {
      height: 32px !important;
      padding: 0 !important;
      margin-left: 12px;
      align-items: center;
      align-self: flex-end;
    }
  }
}

.custom-separator-list-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  width: 100%;
  .search-result-list {
    .search-result-list-item {
      display: block;
    }
  }
}

.list-item-divider {
  height: 1px;
  background-color: @opposite-primary-color;
  border: none;
  margin-top: 0;
}

.pagination {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -0%);
  width: 100%;
}

.ant-list {
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 300px);
}

.ant-list::-webkit-scrollbar, textarea::-webkit-scrollbar {
  width: 8px;
}

.ant-list::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
  background-color: #8b9ed3;
  border-radius: 8px;
}

.tab-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  h2,
  p {
    color: @text-color;
    width: 100%;
    margin: 0;
  }
  .test-chat-button-container {
    margin-top: 6px;
  }
}

.add-new-data-tab {
  .ant-tabs-tab-btn {
    color: @text-color;
    font-weight: 600;
  }
}

.add-by-url-tab {
  .ant-form-item {
    max-width: 700px;
  }
}

.ant-tabs-tab-btn {
  color: @text-color;
}

.edit-data-container {
  width: 100%;
  margin-top: 30px;
  margin-inline: 0;

  ::-webkit-scrollbar {
    width: 5px;
  }

  .ant-form {
    .ant-input {
      width: 329px;
    }
  }
}

.search-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  .ant-form-item {
    width: 329px;
    margin-right: 10px;
  }
  .transparent-button-large {
    margin: 0;
  }
}

.container {
  width: 100%;
  padding: 10px 37px 50px;

  #choose-position-text {
    display: inline;
    padding-right: 10px;
  }

  .template-menu-title {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 180px;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: @text-color;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: @text-color;
    padding-top: 10px;
  }
  .button {
    background-color: @text-color;
  }
  .button:hover {
    background-color: @primary-color;
  }
  .colors-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .color-button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 0;
    }
    .color-input-container {
      grid-column: span 4;

      .ant-input {
        height: 100%;
      }
      .apply-button {
        height: 100%;
      }
    }
  }
}

