.app-auth-input-wrapper {
  display: flex!important;
  flex-direction: row!important;
  align-items: center!important;
  font-weight: 400!important;
  padding: 16px 24px 16px 10px!important;
  gap: 16px!important;
  //width: 100%!important;
  height: 56px!important;

  border: transparent;
  background: rgba(10, 37, 64, 0.03) !important;
  border-radius: 30px!important;

  input {
    background-color: transparent !important;
  }
}

