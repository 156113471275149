@import "../../style/variables";

.account-menu-title {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: @text-color;

  .ant-avatar {
    background-color: transparent;
    border: 1px solid rgba(@primary-color, 0.4);
    margin-right: 3px;

    .anticon {
      svg {
        opacity: 0.4;
      }
    }
  }

  .anticon {
    svg {
      opacity: 1;
    }
  }
}

.account-menu-username {
  font-weight: 600;
}

.account-menu-email {
  color: grey;
}

