@import '../../style/variables.less';

.page-description {
  margin-bottom: 32px;
  margin-top: 20px;
}
h1 {
  margin-bottom: 0;
}

.ai-helper-header p {
  width: 40vw;
}

.ai-helper-header {
  width: 100%;
  padding-inline: 37px;
}

