@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '../../style/variables';

@viewport-height: 100vh;

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: @text-color;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: @text-color;
}

#admin-account-email {
  text-align: right;
  width: 100%;
  color: #f74555;
}

.primary-color {
  color: @primary-color;
}

.text-color {
  color: @text-color;
}

.ant-modal-content {
  border: 0.5px solid rgba(10, 37, 64, 0.1);
  border-radius: 20px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05);

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
}

#ioni-main-layout {
  background: #F8F7FA;
  min-height: 100vh;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 72px;

    span {
      margin-top: 8px;
    }
  }

  .ant-layout {
    background: linear-gradient(111.34deg, #F6F6FF 0%, #CBCBFF 100%);
    backdrop-filter: blur(75px);

    .ant-layout-header {
      height: auto;
    }

    .ant-layout-content {
      margin: 0 !important;
    }
  }

  .ant-layout-header {
    padding: 0;
    background: transparent;
    line-height: 0;

    .header {
      display: flex;
      flex-direction: column;
      padding-inline: 37px;
    }
  }
}

.integrate-data-tooltip {
  max-width: 152px !important;

  button {
    margin: 6px 0;
  }
}

.ant-layout-sider-children {
  position: relative;
}

.right-header-item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 24px;

  .ant-space div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .logo {
      display: none !important;
    }
  }

  .test-chat-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 32px;
  }
}

.left-header-item {
  display: flex;
  justify-content: flex-start;
}

.onboarding-modal-under {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.onboarding-modal {
  z-index: 3;
  position: absolute;
  width: 50vw;
  height: 300px;
  border-radius: 20px;
  margin: auto;
  cursor: context-menu;
  background-color: lightgray;
  top: calc(50% - 100px);
  left: calc(50% + 100px);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.onboarding-modal-text-1 {
  font-size: 16px !important;
  margin-bottom: 10px !important;
}

.onboarding-modal-text-2 {
  font-size: 16px !important;
  margin-top: 0 !important;
  margin-bottom: 30px !important;
}

.integration-popup-text-wrapper {
  line-height: 18px;
  padding: 0 12px;
  p {
    font-size: 14px;
    line-height: 24px;
  }
}
.bg-shape {
  position: fixed;
  z-index: -1;
  overflow: hidden;
}

.bg-shape:nth-child(1) {
  width: 100vw;
  height: 160vh;
  left: -3vw;
  top: 30vh;
  background: radial-gradient(50% 50% at 50% 50%, #a954d1 0%, rgba(244, 220, 255, 0) 80%);
}

.bg-shape:nth-child(2) {
  width: 120vw;
  height: 193vh;
  left: 11vw;
  top: -101vh;
  background: radial-gradient(50% 50% at 50% 50%, #78d9fd 0%, rgba(218, 245, 255, 0) 100%);
}

.bg-shape:nth-child(3) {
  width: 80vw;
  height: 95vh;
  left: -40vw;
  top: -50vh;
  background: radial-gradient(50% 50% at 50% 50%, #f74555 0%, rgba(255, 206, 211, 0) 100%);
}

.bg-shape:nth-child(4) {
  width: 47vw;
  height: 76vh;
  left: -27vw;
  top: 45vh;
  background: radial-gradient(50% 50% at 50% 50%, #78d9fd 0%, rgba(218, 245, 255, 0) 100%);
}

#test-chat-paragraph {
  padding-right: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #0a2540b2;
}

.app-menu-container,
.app-menu-container-no-shadow {
  display: none;
}

@media screen and (max-width: 590px) {
  #admin-account-email {
    padding-right: 20px;
  }
  .bg-shape:nth-child(3) {
    width: 93vw;
    height: 133vh;
  }
  .header {
    padding-inline: 0 !important;

    .left-header-item {
      padding-inline: 20px !important;
      .ai-helper-header p {
        width: 100%;
      }
    }

    .right-header-item {
      padding-inline: 20px !important;
      .ant-space {
        div {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          .logo {
            order: 1;
            display: flex !important;
          }
          .account-menu-title {
            order: 2;
          }
          .test-chat-button {
            order: 3;
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            justify-content: space-between;
            margin: 0;
          }
          #invite-to-team-button {
            order: 4;
          }
        }
      }
    }
  }
}

