@import "../../style/variables";

.login-box {
  flex-direction: column;
}

.onboarding-back-btn {
  svg {
    transform: rotate(180deg);
  }
}

.poll-box {
  justify-content: center;
  max-width: 660px;
  max-height: 600px;
  padding: 40px;
  gap: 40px;
  background: #FFFFFF;
  border: 0.5px solid rgba(10, 37, 64, 0.1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.poll-title {
  margin-bottom: 12px;
}

.poll-title-label {
  margin-block-start: 0;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: @form-input-label;

}

.poll-text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 13px 0;
  color: rgba(10, 37, 64, 0.75);
}

.poll-title-label-username {
  color: @primary-color;
}

.poll-wrapper {
  display: flex;
  flex-direction: column;
  //flex: 1 1;

  .ant-steps {
    align-items: center;
    justify-content: center;
    .ant-steps-item {
      margin-right: 25px;
      flex: 0;
      .ant-steps-item-tail {
        margin-inline-start: 0 !important;

        &:after {
          background-color: transparent !important;
        }
      }

      .ant-steps-item-icon {
        margin-inline-start: 0 !important;
      }
    }

    .wait-line {
      width: 155px;
      height: 4px;
      background: rgba(10, 37, 64, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .progress-line {
      width: 155px;
      height: 4px;
      background: @primary-color;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-steps-item-active {
      .progress-line {
        margin-top: 1px;
      }
    }
  }
}

#poll-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 300px;

  .ant-form-item {
    min-width: 490px;
    display: flex;
    justify-content: center;
  }

  .form-item-action-button {
    margin-bottom: 0;
  }
}

.poll-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.poll-content {
  display: flex;
  min-height: 200px;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.poll-content-title {
  display: flex;
  justify-content: center;
  max-width: 400px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0 16px 0;
  color: @form-input-label;
}

.poll-content-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 0.5fr);
  gap: 10px;
  margin-bottom: 40px;
}

.poll-cxm-card {
  cursor: pointer;
  display: flex;
  margin: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  isolation: isolate;
  min-width: 114px;
  height: 80px;
  box-shadow: 0 0 0 .5px rgba(10, 37, 64, 0.1),
  0 0 0 1.5px rgba(10, 37, 64, 0);
  border-radius: 10px;
  border: none;
  background-color: @opposite-primary-color;
}

.poll-cxm-card-selected {
  position: relative;
  background: rgba(113, 105, 251, 0.1);
  box-shadow: 0 0 0 2px @primary-color;
  border-radius: 10px;
}

.poll-cxm-card-selected-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 7px;
  position: absolute;
  width: 10px;
  height: 7px;
  right: -1px;
  top: -1px;
  background: @primary-color;
  border-radius: 0 10px;
}


@media screen and (max-width: 768px) {
  .poll-box {
    height: 680px;
  }

  .poll-content {
    margin: 0 30px;
  }
}

