#table {
  overflow: auto;
  box-shadow: 0 3px 20px 1px #00000008;
  background: #ffffff73;
  cursor: pointer;
  border-radius: 10px;
}
#container {
  width: 100%;
  padding-top: 20px;
  padding-inline: 37px;
}

@media screen and (max-width: 590px) {
  #table {
    margin-inline: 20px;
    height: 40vh;
  }
}

