@import '../../../style/variables.less';

#transparent-button {
  width: 200px;
  height: 44px;
  padding: 10px 35px 10px 35px;
  border-radius: 48px;
  border: 2px;
  gap: 10px;
  box-sizing: border-box;
  border: 2px solid @form-input-label;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: @form-input-label;
  background-color: transparent;
}
#transparent-button:hover {
  color: @opposite-primary-color;
  background: @message-color;
  border: 0px;
}

#transparent-button.transparent-button-small {
    width: 166px;
    height: 44px;
}

#transparent-button.transparent-button-medium {
    width: 200px;
    height: 44px;
}

#transparent-button.transparent-button-large {
    width: 329px;
    height: 48px;
}
