@import '../../../../style/variables';

#ioni-layout-sider {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 3px 20px 1px #00000008;
  background: #F8F7FA;

  .ant-layout-sider-zero-width-trigger {
    top: 14px;
  }

  .ant-menu {
    margin-top: 24px;
    border: 0;
    background: transparent;

    .ant-menu-item {
      width: 274px;
      height: 49px;
      isolation: isolate;
      border-radius: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      margin-left: 0;

      .ant-menu-title-content {
        margin-left: 20px;
        color: @text-color;
      }
    }

    .ant-menu-item:hover {
      background-color: @opposite-primary-color;
    }

    .ant-menu-item-selected {
      background-color: @opposite-primary-color;
    }
  }

  .sider-menu-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 24px;

    #app-secondary-button {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      padding: 12px 18px;
    }
  }
}

@media screen and (max-width: 590px) {
  #ioni-layout-sider {
    //display: none;
  }
  .app-menu-container,
  .app-menu-container-no-shadow {
    display: block;
  }
}

