@import '../../../../../../src/style/variables.less';

.storage-form-container {
  width: 100%;
  border-radius: 8px;
  .edit-storage-form {
    margin-top: 12px;
  }
  .form-collapse-container {
    margin-bottom: 24px;
    max-width: 400px;
    background-color: transparent;
    border: none;
    .ant-collapse-item {
      border: none;
    }
    .form-collapse-panel {
      .ant-collapse-header {
        padding-left: 0;
        padding-right: 0;
      }
      .ant-collapse-content {
        background-color: transparent;
        border: none;
      }
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
  .storage-form-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    .create-storage-button {
      padding: 0 !important;
    }
    .delete-storage-button {
      margin-right: 12px;
      background-color: @primary-danger !important;
      border-color: @primary-danger !important;
      color: @opposite-primary-color !important;
      justify-content: center;
      padding: 0 !important;
    }
  }
}

