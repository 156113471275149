@import '../../style/variables.less';

* {
  box-sizing: border-box;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
}

.call-to-action-tab {
  .checkbox-group-container {
    display: flex;
    flex-direction: column;

    .checkbox-group-item {
      margin-top: 30px;
      width: 100%;
      h3 {
        margin: 0;
      }

      div {
        display: flex;
        //flex-direction: row;
        max-width: 500px;
        justify-content: space-between;
      }
      p {
        margin-block: 0;
        width: 100%;
      }
      .ant-form-item-row {
        width: 100%;
        div {
          width: 100%;
        }
      }
    }
  }

  .ant-form-item {
    .ant-input,
    .ant-input-disabled {
      max-width: 500px;
    }
  }

  .template-textarea {
    cursor: auto;
  }
}

.settings-tab {
  .ant-form-item {
    div {
      max-width: 400px;
      width: 100%;
    }
    .ant-input {
      width: 100%;
      max-width: 400px;
    }
  }
  .update-agent-form-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    margin-bottom: 12px;
    .create-agent-button {
      padding: 0 !important;
      margin-top: 0 !important;
      min-width: 188px;
    }
    .delete-agent-button {
      margin-right: 12px;
      background-color: @primary-danger !important;
      border-color: @primary-danger !important;
      color: @opposite-primary-color !important;
      justify-content: center;
      padding: 0 !important;
      min-width: 188px;
    }
  }
  .set-default-agent-button-wrapper {
    max-width: 400px;
  }
  .set-default-agent-button {
    width: 100% !important;
    max-width: 400px;
    background-color: @btn-primary-hover-color !important;
    color: @opposite-primary-color !important;
  }
  .storage-select-container {
    width: 100%;
    margin-bottom: 12px;
    .storage-select {
      width: 100%;
      max-width: 400px;
    }
    .storage-select-label {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 8px;
    }
  }
}

h3 {
  color: @text-color;
}
.rcp-root {
  width: 330px;
}

.container {
  width: 100%;
  padding: 10px 37px 50px;

  #choose-position-text {
    display: inline;
    padding-right: 10px;
  }

  .template-menu-title {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 180px;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: @text-color;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: @text-color;
    padding-top: 10px;
  }
  .button {
    background-color: @text-color;
  }
  .button:hover {
    background-color: @primary-color;
  }
  .colors-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .color-button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 0px;
    }
    .color-input-container {
      grid-column: span 4;

      .ant-input {
        height: 100%;
      }
      .apply-button {
        height: 100%;
      }
    }
  }

  #widget-content-container {
    margin-top: 15px;
    #widget-text {
      line-height: 22px;
      overflow-y: auto;
      border: 2px solid @primary-color;
      border-radius: 10px;
      padding: 10px;
      background-color: @opposite-primary-color;
      color: @text-color;
      margin-top: 0px;
      cursor: auto;
      max-width: 320px;
    }
    div {
      display: flex;
      max-width: 320px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .copy-button {
      width: 60px;
      height: 30px;
      color: #0a2540;
    }
  }
}

.empty-storages-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#title-input {
  max-width: 533px;
  margin-right: 20px;
}

.transparent-button-large {
  margin-top: 30px;
}
.tooltip {
  position: relative;
  display: block;
  width: fit-content;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 500px;
  bottom: 100%;
  left: 50%;
  margin-left: -250px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

@media screen and (max-width: 590px) {
  .container {
    padding-inline: 20px;

    #widget-text {
      width: 100%;
    }

    #title-input {
      width: 100%;
    }
  }
}

