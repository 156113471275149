@import '../../style/variables.less';

* {
  box-sizing: border-box;
}

.space-chat-container {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-inline: 20px;
  .chat-messages-list-container {
    height: inherit;
    margin-bottom: 12px;
  }
}

#form-button {
  height: 48px;
  background: @text-color;
  border-radius: 48px;
  color: @opposite-primary-color;
  text-transform: uppercase;
  box-shadow: 0 3px 20px 1px #00000008;
}
#form-button:hover {
  background: @message-color;
}

.form-item-container {
  background-color: @opposite-primary-color;
  box-shadow: 0 3px 20px 1px #00000008;
  border-radius: 10px;
  padding: 16px 0 8px 0;
  margin-bottom: 16px;
  .ant-form-item {
    margin: 0 !important;
  }
  .attach-images-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    background-color: @primary-color;
    color: @opposite-primary-color;
    border-radius: 50%;
    &:hover {
      color: @card-border-color !important;
    }
  }
  .attach-images-button-disabled {
    background-color: @card-border-color !important;
  }
  .form-item-message-textarea {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 15px;
    cursor: auto;

    &:not(.ant-input-status-error) {
      border-color: transparent;
    }
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
    &:focus-visible {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  .ant-input:disabled {
    background-color: @opposite-primary-color;
  }
}

.attach-image-popover {
  .popover-button {
    width: 100%;
    height: 24px;
    font-size: 10px;
    border-radius: 12px;
    text-transform: uppercase;
    box-shadow: 0 3px 20px 1px #00000008;
  }
  .attach-image-by-link-button {
    margin-bottom: 4px;
    background: @text-color;
    color: @opposite-primary-color;
    &:hover {
      background: @message-color;
      color: @opposite-primary-color !important;
      border-color: @message-color !important;
    }
  }
}

.image-previews {
  margin: 0 16px 0 16px;
  .image-preview {
    position: relative;
    margin-right: 8px;
    display: inline-block;
    .added-image-preview {
      border-radius: 4px;
    }
    .remove-preview-image-button {
      position: absolute;
      background-color: @text-color-secondary;
      width: 20px;
      height: 20px;
      top: -5px;
      right: -5px;
      border-radius: 10px;
      border: 1px solid @text-color;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.chat-message-images-preview {
  display: flex;
  flex-wrap: wrap;
  .image-preview {
    position: relative;
    margin-right: 8px;
    display: inline-block;

    .added-image-preview {
      border-radius: 4px;
    }
  }
}

.paragraph-images {
  border-radius: 10px;
  padding: 8px;
}

.paragraph {
  background: @opposite-primary-color;
  box-shadow: 0 3px 5px 1px #00000008;
  min-height: 48px;
  border-radius: 30px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  white-space: pre-line;
  max-width: 30vw;
  width: fit-content;
  word-wrap: break-word;
  color: @text-color;
}

.function-call-paragraph {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
#paragraph-user {
  background: #9966ff26;
}
#user-paragraph-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
#user-message-item {
  justify-content: right;
}
#chat-container {
  background: #ffffff73;
  box-shadow: 0 3px 20px 1px #00000008;
  border-radius: 10px;
  padding: 20px;
  height: 55vh;
  max-width: 100vw;
  overflow: auto;

  ul {
    padding: 0 0 0 21px;
  }
}

@media screen and (max-width: 853px) {
  #chat-container {
    height: 100%;
  }
}

::-webkit-scrollbar-track {
  background: transparent;
}
.chat-header {
  height: 72px;
  background: #ffffffa3;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100vw;
  padding-block: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#chat-header-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 75vw;
}

