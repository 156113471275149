@import "../../../style/variables";

#app-primary-button.app-primary-button-large, #app-primary-button.ant-btn-lg  {
  padding: 16px 100px;
  height: 56px;
}

#app-primary-button.app-primary-button-medium, #app-primary-button.ant-btn-default {
  min-width: 200px;
  height: 48px;
  padding: 12px 40px;
}

#app-primary-button.app-primary-button-small, #app-primary-button.ant-btn-sm{
  min-width: 130px;
  height: 32px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 400;
}

#app-primary-button {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  height: 48px;
  min-width: 200px;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  background: @form-input-label;
  color: @opposite-primary-color;
  border-radius: 30px;
  text-transform: uppercase;
}

#app-primary-button:hover {
  background-color: @message-color;
}

#app-primary-button:disabled {
  border: transparent;
  color: @opposite-primary-color;
}


